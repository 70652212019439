
const pages = {
  CATALOG: "/catalog",

  ACCOUNT: "/account",
  ACCOUNT_ADDRESSES: "/account/addresses",

  LOGIN: "/login",
  FORGOT: "/forgot",
};

export default pages;
