import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Modal } from "antd";

import DeliveryCalculate from "components/modal/DeliveryCalculate";
import SignIn from "components/modal/SignIn";
import YookassaWidget from "components/modal/YookassaWidget";
import HeaderLayout from "./HeaderLayout";
import FooterLayout from "./FooterLayout";
import { setDeliveryModalIsOpen, setSigninModalIsOpen, setYookassaWidgetModalIsOpen } from "store/slices/layoutSlice";

const MainLayout = ({ children }) => {
  const dispatch = useDispatch();
  const { deliveryModalIsOpen, signinModalIsOpen, yookassaWidgetModalIsOpen } = useSelector((state) => state.layout);

  const handleDeliveryModalClose = () => {
    dispatch(setDeliveryModalIsOpen(false));
  };

  const handleSigninModalClose = () => {
    dispatch(setSigninModalIsOpen(false));
  };

  const handleYookassaWidgetModalClose = () => {
    dispatch(setYookassaWidgetModalIsOpen(false));
  };

  return (
    <Layout className="rn-layout">
      <BrowserRouter>
        <HeaderLayout />
        <Layout.Content className="rm-main">{children}</Layout.Content>
        <FooterLayout />
      </BrowserRouter>

      <Modal
        width={1000}
        open={deliveryModalIsOpen}
        onCancel={handleDeliveryModalClose}
        footer={null}
      >
        <DeliveryCalculate />
      </Modal>
      <Modal
        width={420}
        centered
        open={signinModalIsOpen}
        onCancel={handleSigninModalClose}
        footer={null}
      >
        <SignIn />
      </Modal>
      <Modal
        width={500}
        centered
        open={yookassaWidgetModalIsOpen}
        onCancel={handleYookassaWidgetModalClose}
        footer={null}
      >
        <YookassaWidget />
      </Modal>
    </Layout>
  );
};

export default MainLayout;
