import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";

import { fetchCategoriesList } from "store/slices/menuSlice";
import useSmartNavigate from "hooks/useSmartNavigate";
import { loadingStatus } from "helpers/fetcher";

const Megamenu = ({ buttonIcon, topMenuOnly, ...rest }) => {
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.menu.categoriesList);

  const { categoryNavigate } = useSmartNavigate();

  useEffect(() => {
    dispatch(fetchCategoriesList());
  }, [dispatch]);

  return (
    <Dropdown
      menu={{
        items: topMenuOnly ? data.categories?.map(({ children, ...m }) => ({ ...m })) : data.categories,
        onClick: categoryNavigate,
        // onSelect: console.log,
      }}
      trigger={["click"]}
    >
      <Button
        type="primary"
        size="large"
        loading={status !== loadingStatus.SUCCEEDED}
        {...(rest ? rest : {})}
      >
        {buttonIcon ? buttonIcon : <>Каталог <DownOutlined /></>}
      </Button>
    </Dropdown>
  );
};

export default Megamenu;
