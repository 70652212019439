import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, List, Skeleton, Typography } from "antd";

import Breadcrumb from "components/Breadcrumb";
import { fetchCategory } from "store/slices/productSlice";
import useSmartNavigate from "hooks/useSmartNavigate";
import { getImage } from "helpers";
import { loadingStatus } from "helpers/fetcher";
import { useParams } from "react-router-dom";
import { PRODUCTS_GRID } from "constants/breakpoints";

const Catalog = () => {
  const dispatch = useDispatch();
  const { "*": path } = useParams();
  const { data, status } = useSelector((state) => state.product.category);
  const { navigate, getHref } = useSmartNavigate();
  const url = getHref(path);

  useEffect(() => {
    if (url) dispatch(fetchCategory({ url }));
  }, [dispatch, url]);

  return (
    <div className="region">
      <Breadcrumb />
      <Typography.Title level={3}>Каталог</Typography.Title>

      <List
        grid={PRODUCTS_GRID}
        dataSource={data.categories || Array(5 * 3).fill({})}
        renderItem={(item) => (
          <Skeleton active avatar loading={status !== loadingStatus.SUCCEEDED}>
            <List.Item>
              <Card onClick={() => navigate(`/catalog/${item.path}`)}>
                <img
                  src={getImage(item.image)}
                  alt=""
                  className="img-responsive"
                />
                <strong>{item.name}</strong>
              </Card>
            </List.Item>
          </Skeleton>
        )}
      />
    </div>
  );
};

export default Catalog;
