import { Menu } from "antd";
import {
  HeartOutlined,
  HistoryOutlined,
  UserOutlined,
  SecurityScanOutlined,
} from "@ant-design/icons";

import useSmartNavigate from "hooks/useSmartNavigate";

const accountItems = [
  {
    label: "Профиль",
    key: "/account",
    icon: <UserOutlined />,
  },
  {
    label: "Безопасность",
    key: "/account/password",
    icon: <SecurityScanOutlined />,
  },
  {
    label: "История заказов",
    key: "/account/history",
    icon: <HistoryOutlined />,
  },
  {
    label: "Избранные",
    key: "/account/wishlist",
    icon: <HeartOutlined />,
  },
  // {
  //   label: "Возвраты",
  //   key: "/account/return",
  //   icon: <RedoOutlined />,
  // },
  // {
  //   label: "Бонусные баллы",
  //   key: "/account/reward",
  //   icon: <PoundCircleOutlined />,
  // },
];

const AccountMenu = () => {
  const { navigate } = useSmartNavigate();

  const handleMenuClick = ({ item, key, keyPath, domEvent }) => {
    navigate(key);
  };

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={["1"]}
      defaultOpenKeys={["sub1"]}
      items={accountItems}
      onClick={handleMenuClick}
    />
  );
};

export default AccountMenu;
