const PRODUCTS_GRID = {
  gutter: 16,
  xs: 1,
  sm: 2,
  md: 3,
  lg: 3,
  xl: 4,
  xxl: 4,
};

export { PRODUCTS_GRID };